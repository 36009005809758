console.log("hello world test?");

import "../scss/menu.scss";

class MobileMenuHandler {
  private mobileBreakpoint: number = 992;
  private activeModal: HTMLElement | null = null;

  constructor() {
    this.setupLinkClickHandler();
    this.setupBurger();
  }

  // Check if the screen is mobile or not
  public isMobileScreen(): boolean {
    return window.innerWidth < this.mobileBreakpoint;
  }

  private setupBurger() {
    const burger = document.querySelector<HTMLButtonElement>(
      "[data-kfx-menu-burger]"
    );
    const itemsWrapper = document.querySelector<HTMLButtonElement>(
      "[data-kfx-menu-items]"
    );
    if (!burger || !itemsWrapper) return;
    burger.addEventListener("click", () => {
      burger.classList.toggle("active");
      itemsWrapper.classList.toggle("active");
      if (this.activeModal) {
        this.activeModal.classList.remove("submenu-shown");
        const parent = document.querySelector<HTMLElement>("[data-kfx-menu-items]");
        if(parent) parent.classList.remove("submenu-shown");
        this.activeModal = null;
      }

      const bodyClass = burger.classList.contains("active") ? "hidden" : "auto";
      document.body.style.overflow = bodyClass;
    });
  }

  // Set up click handlers for links with data-kfx-menu-child-wrapper attribute
  private setupLinkClickHandler(): void {
    const linkElements = document.querySelectorAll<HTMLElement>(
      "a[data-kfx-menu-child-wrapper]"
    );
    linkElements.forEach((linkElement) => {
      linkElement.addEventListener("click", (event) =>
        this.handleLinkClick(event, linkElement)
      );
    });

    const hideModalTriggers = document.querySelectorAll<HTMLButtonElement>(
      "[data-kfx-menu-child-hide-trigger]"
    );
    hideModalTriggers.forEach((triggerElement) => {
      triggerElement.addEventListener("click", () => {
        this.hideModal(triggerElement);
      });
    });
  }

  // Handle anchor link behavior based on screen size
  private handleLinkClick(event: Event, linkElement: HTMLElement): void {
    if (this.isMobileScreen()) {
      event.preventDefault();
      this.openModal(linkElement);
    }
  }

  // Logic for opening modal
  private openModal(linkElement: HTMLElement): void {
    const el = linkElement.nextElementSibling as HTMLElement;
    const parent = document.querySelector<HTMLElement>("[data-kfx-menu-items]");
    if (!el || !parent) return;
    el.classList.add("submenu-shown");
    parent.classList.add("submenu-shown");
    this.activeModal = el;
  }

  private hideModal(triggerElement: HTMLElement): void {
    const el = triggerElement.parentElement;
    const parent = document.querySelector<HTMLElement>("[data-kfx-menu-items]");
    if (!el || !parent) return;
    el.classList.remove("submenu-shown");
    parent.classList.remove("submenu-shown");
    this.activeModal = null;
  }
}

new MobileMenuHandler();
